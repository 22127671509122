<template>
  <div id="treaty">
    <div class="container">
      <h2 v-if="!$route.query.lang" class="treaty-title">{{ $t('user.treaty_title') }}</h2>
      <div v-html="$t('user.treaty')"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Treaty',
  data() {
    return {
    }
  },
  mounted() {

  },
}
</script>
<style lang="scss">
#treaty {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;

  .treaty-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .subtitle {
    font-weight: 600;
    text-indent: 0;
  }

  p {
    text-indent: 1em;
  }
}
</style>